exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-acatist-index-tsx": () => import("./../../../src/pages/acatist/index.tsx" /* webpackChunkName: "component---src-pages-acatist-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-acatist-tsx": () => import("./../../../src/templates/acatist.tsx" /* webpackChunkName: "component---src-templates-acatist-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

